* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
p {
  margin: 0 !important;
}

body {
  background-color: black !important;
}

.myIconContentBox {
  border: 2px solid red;
  border-radius: 50%;
  width: 23px;
  height: 23px;
  margin: auto;
  transform: translate(-2px, -4px);
  position: relative;
}

.myIconContentBox.active .myIconContentPopover {
  display: block !important;
}

.myIconContentBox.active {
  border-color: black;
}

.myIconContentBox.edit {
  border-color: #56db40;
}

.myIconContentPopover {
  width: 100%;
  max-width: 300px;
  min-width: 150px;
  position: absolute;
  bottom: 23px;
  left: -65px;
  /* right: 0px; */
  background-color: #182335;
  padding: 5px;
  border-radius: 5px;
  color: white;
  display: none;
  margin: auto;
}

mark {
  background-color: #fd8a8a;
  padding: 0;
}

mark.date {
  background-color: #c8ffd4;
}
mark.time {
  background-color: #03c988;
  /* background-color: #b8e8fc; */
}
mark.registration {
  background-color: #b1afff;
}
mark.e_vent {
  background-color: #fdfdbd;
}
mark.quotation {
  background-color: #ffdca9;
}

mark.fullName {
  background-color: #e5b8f4;
}

mark.uppercase {
  background-color: lightblue;
}

mark.year {
  background-color: lightblue;
}

mark.none_event_hashtag {
  background-color: lightcyan;
}

mark.none_event_word {
  background-color: lightcyan;
}

mark.past_simple {
  background-color: lightgreen;
}

mark.format {
  background-color: lightpink;
}
